import * as React from "react";
import { parse } from 'query-string';
import Layout from "../../components/Layout";
import ProductCardGrid from "../../components/ProductCardGrid";
import Breadcrumbs from "../../components/Breadcrumbs"

const BrowsePage = (props) =>{
  const params = parse(props.location.search);
  // parse(props.location.products.browse);
  const category = params.category ? params.category : 'All Products';

  return (
    <Layout>
    <div
      className="full-width-image-container margin-top-0 darken-image"
      style={{
        backgroundImage: `url('/img/blog-index.jpg')`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          color: "white",
          padding: "1rem",
        }}
      >
        {category}
      </h1>
    </div>
    <section className="section">
      <div className="container">
      <Breadcrumbs
      crumbs={[
        { link: '/', label: 'Home' },
        { label: 'Products', link: '/products' },
        { label: `${category}`},
      ]}
      /> 
        <div className="content">
          <ProductCardGrid/>
        </div>
      </div>
    </section>
  </Layout>
  );
};

export default BrowsePage;