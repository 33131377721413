import React, { useState } from 'react';
import { navigate } from 'gatsby';
import * as styles from './ProductCard.module.css';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
const ProductCard = (props) => {
  const {
    key = 'sample1',
    image,
    imageAlt,
    name,
    height = 580,
  } = props;

  const handleRouteToProduct = () => {
    navigate(`/products/${key}`);
  };


  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        style={{ height: `${height}px` }}
        onClick={() => handleRouteToProduct()}
        role={'presentation'}>
        <PreviewCompatibleImage imageInfo={image} alt={imageAlt}/>
        {/*<img style={{ height: `${height}px` }} src={image} alt={imageAlt}></img>*/}
      </div>
      <div className={styles.detailsContainer}>
        <span className={styles.productName}>{name}</span>
      </div>  
    </div>
  );
};

export default ProductCard;