import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import * as styles from './ProductCardGrid.module.css';
import ProductCard from '../ProductCard';
import Slider from '../Slider';

const ProductCardGridTemplate = (props) => {

  const { edges: posts } = props.data.allMarkdownRemark;
  const height = 450, columns = 3, spacing = 10, showSlider = false;
  const columnCount = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };


  const renderCards = () => {
    return posts.map(({node: post}) => {
      return (
        <ProductCard
          key={post.frontmatter.key}
          height={height}
          imageAlt={post.frontmatter.title}
          name={post.frontmatter.title}
          image={post.frontmatter.product.blurbs[0].image}
        />
      );
    });
  };

  return (
    <div className={styles.root} style={columnCount}>
      <div
        className={`${styles.cardGrid} ${
          showSlider === false ? styles.show : ''
        }`}
        style={columnCount}
      >
        {posts && renderCards()}
      </div>

      {showSlider === true && (
        <div className={styles.mobileSlider}>
          <Slider spacing={spacing}>{posts && renderCards()}</Slider>
        </div>
      )}
    </div>
  );
};

ProductCardGrid.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function ProductCardGrid() {
  return (
    <StaticQuery
      query={graphql`
        query ProductCardGridQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "product-detail-page" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  category
                  product {
                    blurbs {
                      image {
                        childImageSharp {
                          gatsbyImageData(quality: 64, layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ProductCardGridTemplate data={data} count={count} />}
    />
  );
}
